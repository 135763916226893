import { useEffect, useState } from "react";
import { mycolors } from "../helpers/mycolors";
import { useNavigate } from 'react-router-dom';
import TopMenuLayout from "../components/TopMenuLayout";

const TemporaryScreen2 = () => {

    const ekbilgi = [
        'The project is behind schedule; moreover, it is over budget. (dahasi, ayrica)',
        'The restaurant offers excellent food; furthermore, the service is outstanding. (ustelik, ayrica)'
    ];

    const zitlikvekarsilastirma = [
        'The weather was rainy; however, they still went for a walk. (ancak, ancak buna rağmen)',
        'I wanted to go to the party; however, I had to finish my work. (ancak, ancak buna rağmen)',
        'He loves to travel; on the other hand, his wife prefers to stay home. (diğer taraftan, diğer yandan)',
        'She was tired; nevertheless, she continued working. (yine de, buna ragmen)',
        'Although he studied hard, he didn’t pass the exam. (olmasina ragmen, her ne kadar)',
        'Even though he was tired, he kept working. (olmasina ragmen, her ne kadar)'
    ];

    const sebepsonuc = [
        'The project was delayed; therefore, the deadline was extended. (bu nedenle, dolayısıyla)',
        'He failed to meet the requirements; consequently, he was not hired. (sonuç olarak, bunun sonucunda)',
        'The students did not follow instructions; as a result, they made mistakes. (sonuç olarak)'
    ];

    const aciklamavedetaylandirma = [
        'She is a great leader, in other words, she inspires everyone around her. (başka bir deyişle)',
        'To clarify, we need all the reports submitted by Friday. (açıklamak gerekirse)'
    ];

    const extras = [
        'That being said, I’d like to suggest a small improvement for future potlucks. Bu cümlede, kişi muhtemelen daha önce pozitif bir şey söylemiş olabilir (örneğin, potluck harikaydı, herkes çok uğraşmış vs.). Ama ardından bir öneri veya eleştiri getirecek. İşte o geçişi yumuşatmak ve nazikçe yapmak için "That being said" kullanılıyor.',
        'Thankfully, I noticed the taste immediately and stopped eating it, but the situation could have been dangerous. ("Thankfully" kelimesi burada "şükür ki", "neyse ki", "iyi ki" anlamında kullanılmış. Bu ifade, olumsuz ya da tehlikeli bir durumun daha kötüye gitmesini engelleyen bir şey olduğunu belirtmek için kullanılır. Yani kişi şükredilecek bir durumdan bahsediyor.)'
    ]

    const ekbilgiSentences = ekbilgi.sort(() => Math.random() - 0.5);
    const zitlikvekarsilastirmaSentences = zitlikvekarsilastirma.sort(() => Math.random() - 0.5);
    const sebepsonucSentences = sebepsonuc.sort(() => Math.random() - 0.5);
    const aciklamavedetaylandirmaSentences = aciklamavedetaylandirma.sort(() => Math.random() - 0.5);
    const extraSentences = extras.sort(() => Math.random() - 0.5);

    return (
        <div style={{ width: '100%', backgroundColor: mycolors.backGroundColor, display: 'flex', alignItems: 'center', flexDirection: 'column' }}>

            <div style={{ width: '90%', backgroundColor: mycolors.backGroundColor, display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                <h3 style={{ color: mycolors.generalColor }}>Ek bilgi</h3>
                {ekbilgiSentences.map((sentence, index) => (
                    <p style={{ color: mycolors.generalColor, fontSize: 14, fontWeight: '500' }} key={index}>{sentence}</p>
                ))}

                <h3 style={{ color: mycolors.generalColor }}>Zitlik ve karsilastirma</h3>
                {zitlikvekarsilastirmaSentences.map((sentence, index) => (
                    <p style={{ color: mycolors.generalColor, fontSize: 14, fontWeight: '500' }} key={index}>{sentence}</p>
                ))}


                <h3 style={{ color: mycolors.generalColor }}>Sebep ve sonuc</h3>
                {sebepsonucSentences.map((sentence, index) => (
                    <p style={{ color: mycolors.generalColor, fontSize: 14, fontWeight: '500' }} key={index}>{sentence}</p>
                ))}

                <h3 style={{ color: mycolors.generalColor }}>Aciklama ve detaylandirma</h3>
                {aciklamavedetaylandirmaSentences.map((sentence, index) => (
                    <p style={{ color: mycolors.generalColor, fontSize: 14, fontWeight: '500' }} key={index}>{sentence}</p>
                ))}

                <h3 style={{ color: mycolors.generalColor }}>Extra</h3>
                {extraSentences.map((sentence, index) => (
                    <p style={{ color: mycolors.generalColor, fontSize: 14, fontWeight: '500' }} key={index}>{sentence}</p>
                ))}

            </div>
        </div>
    )

}

export default TemporaryScreen2;